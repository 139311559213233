/* This file contains CSS classes that apllies to the whole webpage */

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pridi:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* Color Palette */
:root {
    /* Nav */
    --navBgColor1: #F4D3DC;
    --navBgColor2:#986C87;
    --navLink: white;
    --navActiveLink: black;

    /* All Content */
    --contentBg: #F4D3DC;
    --contentShadow: rgba(255, 255, 255, 0.6);
    --contentH1: #986C87;
    --contentH2: #986C87;    
    --contentP: #986C87;
    --contentH4: #986C87;

    /* Content3 */
    --pricelistHover: #eec8d2;   
    --pricelistTableFont: #986C87;

    /* Content4 */
    --btnBg: #986C87;
    --btnFont: white;
    --btnHoverBg: white;
    --btnHoverFont: #986C87;


}
* {
    margin: 0px;
    padding: 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0px;
    padding: 0px;   
    background: url('/src/assets/img/background.jpg'); 
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
}

.content {
    display: flex;
    flex-direction: column;        
    margin: 3rem;   
    padding: 2rem;    
    min-height: 55vh;
    background-color: var(--contentBg);    
    box-shadow: inset 0px 0px 10px 10px var(--contentShadow);  
}

.content h1 {
    font-family: "PT serif";
    font-size: 4rem; 
    /* font-weight: bold; */
    text-transform: uppercase;
    text-align: center;
    color: var(--contentH1);    
}

.content h2 {
    font-family: "Playball", cursive;
    font-size: 2rem;   
    text-transform: uppercase;
    margin: 0px;
    margin-top: 1rem;    
    margin-bottom: 0.5rem;
    color: var(--contentH2);
}

.content h4 {
    font-family: "Red Hat Mono", cursive;
    color: var(--contentH4);
}

.content p {
    font-family: "Red Hat Mono", cursive;
    color: var(--contentP);
    text-align: justify;
    padding: 0.5rem;
}

.impressum {
    margin-top: 1rem;    
    height: 90vh;
    min-height: 90vh;
}

.impressum h1 {
    font-family: "PT serif";
    font-size: 4rem; 
    color: var(--contentH4);
    text-align: center;
}

.impressum h5 {
    font-family: "Red Hat Mono", cursive;
    font-size: 1.5rem;
    margin: 0px;
    margin-top: 1rem;    
    margin-bottom: 0.5rem;
    color: var(--contentH2);
}

.impressum p {
    font-family: "Red Hat Mono", cursive;
    color: var(--contentP);
    padding: 0.2rem;
}

.impressum_content {
    width: 90%;
    margin: 2rem;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1200px) {

}

@media (max-width: 990px) {
    .content h1{
        font-size: 3rem;
    }

    .content p{
        font-size: smaller;
    }

    .content.pricelist_table th{
        font-size: x-small;
    }
    .impressum h1{
        font-size: 2.5rem; 
    }
    .impressum h5{
        font-size: 1.3rem;
    }
    .impressum p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .content{
        min-height: 40vh;
        
    }
    .content h1{
        font-size: 2rem;
    }
    .impressum h1{
        font-size: 2rem; 
    }
    .impressum h5{
        font-size: 1rem;
    }
    .impressum p {
        font-size: 0.8rem;
    }
}

@media (max-width: 500px) {
    .content{
        margin: 1rem;
    }
    .content h1{
        font-size: 1.5rem;
    }

    .content h2{
        font-size: 1.4rem;
    }
    .content p{
        font-size: small;
    }
    .impressum h1{
        font-size: 1.5rem; 
    }
    .impressum h5{
        font-size: 0.9rem;
    }
    .impressum p {
        font-size: 0.6rem;
    }
}