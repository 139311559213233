.pricelist{
    display: block;
}
.pricelist_table {
    font-family: "Pridi", cursive;
    font-size: 1.2em;
    color: var(--pricelistTableFont);
    margin: 2em; 
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.pricelist_table th {        
    font-size: 1.8em;     
    font-weight:lighter;
    text-transform: uppercase;
    margin: 0.5em;
    margin-top: 4em;   
    padding: 0.1em;
    padding-top: 1em; 
    border-bottom: 2px solid var(--contentShadow);
}

.pricelist_hoverable:hover {
    background-color: var(--pricelistHover);
}

.pricelist_item_name {
    margin: 0.5em;
    padding: 0.5em;
    padding-left: 4em;
}
@media (min-width: 600px) {
    .artificial_nails{
        display: table-cell;
    }
    .lenght{
        display: table-cell;
    }
    .artificial_nails_2{
        display: none;
    }
    .price_lenght{
        display: none;
    }
}
@media (max-width: 1200px) {
    
}

@media (max-width: 990px) {
    .pricelist_table{
        width: 80%;
    }
    .pricelist_table th{
        font-size: 1.4em;
    }

    .pricelist_table td{
        font-size:0.8em;
        padding:1%;
    }
}

@media (max-width: 768px) {
    
}

@media (max-width: 600px) {
    .pricelist_table{
        width: 100%;
    }
    .pricelist_table th{
        font-size: 0.9em;
    }
    .artificial_nails{
        display: table-cell;
    }
    .price{
        font-size: 0.8em;
    }

    .lenght{
        display: table-cell;
    }

    .pricelist_lenght{
        font-size: 0.8em;
    }
    .price_lenght{
        font-size: 0.8em;
        display: none;
    }

    .pricelist_item_name{
        font-size: 0.9em;
        padding-left: 0;
    }
    .artificial_nails_2{
        display: none;
    }
}

@media (max-width: 500px) {
    .pricelist_table th{
        font-size: 0.7em;
    }
    .artificial_nails{
        display: none;
    }
    .price{
        font-size: 0.6em;
        white-space:pre;
    }

    .lenght{
        display: none;
    }

    .pricelist_lenght{
        font-size: 0.6em;
        display: flow;
    }

    .pricelist_item_name{
        font-size: 0.7em;
        padding-left: 0;
    }
    .artificial_nails_2{
        display: table-cell;
    }
    .price_lenght{
        display: flow;
    }
}