/* to modify bootstrap css */
div.row{
    --bs-gutter-x:0rem;
}

a.nav_link {
    padding: 0px;
    padding-left: 0.4em;
    padding-right: 0.4em;
}

.navbar-light {
    padding: 0px;
}

.navbar-nav {
    padding: 0px;
    margin: 0px;
}

.navbar-nav ul {
    padding: 0;
    margin-left: 20vw;
    margin-right: 20vw;
    margin-top: auto;

}

.navbar {
    width: 100%;
    top: 0;
    left: 0;
}

/*  */

.navbar1 {
    position: relative;
    z-index: 999;
    height: 80px;
    width: 100%;
    background: linear-gradient(var(--navBgColor1) 20%, var(--navBgColor2));
    font-family: "Courier Prime", monospace;
    transition: all 0.5s ease-in-out;
}

.navbar_sticky {
    position: fixed;
    top: 0;
    left: 0;
}

.nav_content {
    background: linear-gradient(var(--navBgColor1) 20%, var(--navBgColor2));
    width: 100%;
}

.nav_content.container {
    max-width: 100%;
}

.nav_link {
    font-size: 1.6rem;
    color: var(--navLink);
    text-decoration: none;
    overflow: hidden;
    display: inline-block;
    position: relative;
    padding: 0.2rem 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.nav_link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #986C87;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

.nav_link::after {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
}

.nav_link:hover::after {
    transform: translate3d(0, 0, 0);
}
.navlogo{
    margin-left: 0;
}
.nav_link img {
    max-height: 80px;
}

.navlogo_little{
    display: none;
}

#toggleNavBtn {
    margin-right: 0.4rem;
    margin-left: auto;
    margin-bottom:1.2rem;
    margin-top: 20px;
}

.active_link {
    font-weight: bolder;
    color: #46313d;
}


/* Optimize size to large/medium/small */
@media (max-width: 1600px) {

    .nav_content.container{
        padding: 0 !important;
    }
    .navbar-nav ul {
        margin-left: 15vw;
        margin-right: 10vw;
    }

}
@media (max-width: 1510px) {
    .navbar {
        width: 100%;
    }

    .nav_link {
        font-size: 1.4rem !important;
    }

    .navbar-nav ul {
        margin-left: 15vw;
        margin-right: 15vw;
    }

}
@media (max-width: 1200px) {
    .navbar {
        width: 100%;
    }

    .nav_link {
        font-size: 1.4rem !important;
    }

    .navbar-nav ul {
        margin-left: 10vw;
        margin-right: 10vw;
    }

}

@media (max-width: 990px) {
    .navbar1 ul {
        display: contents;
        text-align: right;
    }

    /* .nav_link {
        font-size: 1.4rem !important;
    } */

    .navbar-nav {
        height: 100vh;
        background: linear-gradient(var(--navBgColor2), var(--navBgColor1) 50%);
        right: 0;
        width: 0;
        transition: width 70s;
        transition-timing-function: ease-in-out;
        margin-left: auto;
    }

    .floatrightNavbar {
        width: 25vw;
    }

    .me-auto {
        margin-right: 0 !important;
    }

    .navbar-collaspe {
        transition: width 2s, height 2s, transform 2s;
    }

    .nav_content.container {
        background: none;
        padding: 0;
    }
    
    .navlogo{
        display: none;
    }

    .navlogo_little{
        display: block;
    }
    
}

@media (max-width: 768px) {

    .nav_link {
        font-size: 1.3rem !important;
    }

    .floatrightNavbar {
        width: 30vw;
    }
}

@media (max-width: 500px) {
    .nav_link img {
        max-height: 60px;
    }
    .nav_link {
        font-size: 1.2rem !important;
    }
}
@media (max-width: 375px) {
    .nav_link img {
        max-height: 60px;
    }
    .nav_link {
        font-size: 1.1rem !important;
    }
    .floatrightNavbar {
        width: 40vw;
    }

}