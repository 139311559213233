.munkaim {
    display: block;
}

.mywork_loading {
    display: flex;
    height: auto;
    margin: auto;
    text-align: center;
}

.mywork_content_1 {
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.mywork_tile_img {
    width: 25vw;
    height: auto;
    padding: 1rem;
    border-radius: 2rem;
    /* image-orientation: from-image; */
    cursor: pointer;
    /* transition: 0.3s; */
}

.mywork_content_1 img:hover {
    opacity: 0.7;
}

/* The Modal (background) */
.mywork_modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

/* Modal Content (Image) */
.mywork_modal_content {
    height: 80vh;
    width: auto;
    margin: auto;
    margin-top: 10vh;
}

/* The Close Button */
.close {
    z-index: 2;
    position: fixed;
    top: 1vh;
    right: 1vh;
    color: #f1f1f1;
    font-size: 3rem;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}


/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
    .mywork_modal-content {
        width: 100%;
    }
}

.mywork_btn {
    text-decoration: none;
    width: auto;
    margin: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    border-radius: 0.5rem;
    font-family: "Red Hat Mono", cursive;
    font-size: 1.5rem;
    background-color: var(--btnBg);
    color: var(--btnFont);
    border: 0px;
}

.mywork_btn:hover {
    background-color: var(--btnHoverBg);
    color: var(--btnHoverFont);
    font-weight: bold;
}

@media (max-width: 1200px) {
    .mywork_btn {
        font-size: 1.5em;
    }
    .mywork_tile_img {
        width: 35vw;
    }
}
@media (max-width: 1100px) {
    .mywork_modal_content {
        height: 50vh;
        margin-top: 0vh;
        margin: auto;
    }
}

@media (max-width: 800px) {
    .mywork_btn {
        font-size: 1.2em;
    }

    .mywork_modal_content {
        height: 50vh;
    }
}
@media (max-width: 700px) {

    .mywork_modal_content {
        height: 40vh;
    }
    .mywork_tile_img {
        width: 35vw;
    }
}
@media (max-width: 600px) {

    .mywork_modal_content {
        height: 40vh;
    }
}
@media (max-width: 533px) {

    .mywork_tile_img {
        width: 50vw;
    }
}

@media (max-width: 500px) {

    .mywork_modal_content {
        height: 30vh;
    }

}

@media (max-width: 400px) {
    .mywork_btn {
        font-size: 0.9em;
    }

    .mywork_tile_img {
        width: 70vw;
    }

    .mywork_modal_content {
        height: 28vh;
    }
}