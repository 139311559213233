.about {
    display: flex;
    flex-direction: row;
}

.about_content_1 {
    margin-bottom: 0px;
}

.about_content_2 {
    width: 90%;
    float: right;
}

.about_content_1_img {
    float: left;
    max-height: 16vh;
    width: auto;
    border-radius: 8%;
    margin: 0.7rem;
    margin-top: 1rem;
}

.about_content_1 h4 {
    padding-left: 0.5rem;
}

.about_content_1 p {
    text-align: justify;
    font-size: 1.1rem;
}

.about_content_2_img {
    /* width: 90%; */
    transform: rotate(180deg);
    padding: 0px;
}

@media (max-width: 1200px) {
    .about_content_2_img {
        width: 100%;
    }

    .about_content_1 {
        width: 90%;
    }
}

@media (max-width: 990px) {
    .about_content_2 {
        display: none;
    }
}

@media (max-width: 768px) {
    .about_content_1 p {
        font-size: 0.9rem;
    }
}

@media (max-width: 500px) {

    .about_content_1 {
        width: 100%;
    }

    .about_content_1 h4 {
        font-size: 1.2rem;
        text-align: center;
    }

    .about_content_1_img {
        display: block;
        margin-right: auto;
        margin-left: auto;
        float: none;
    }

    .about_content_1 p {
        font-size: 0.8rem;
        word-spacing: -0.3rem;

    }
}