.right_tulip {
    transform: rotateY(-180deg);
    /* padding: 0px; */
}

.left_tulip {
    padding: 0px;
}

.footer {
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    position: relative;
    text-align: left;
    vertical-align: middle;
    margin: 0px;
    background: linear-gradient(#C994A2, #592846);
    color: #F4D3DC;
    font-style: bold;
    padding: 1.5rem;
    padding-bottom: 0px;
    font-family: "Courier Prime", monospace;
    font-weight: 400;
    font-style: normal;
}

.footer_logo img {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2%;
    padding-bottom: 5%;
}

.footer h4 {
    text-align: left;
    margin: 0px;
    font-size: 1.6rem;
    font-weight: bold;
}

.footer h4 i {
    padding: 15px;
}

.footer table {
    width: 85%;
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
}

.footer td {
    margin: 0px;
    padding-bottom: 1%;
    padding-top: 1%;
    font-size: 1.1rem;
}

.footer ul {
    list-style-type: none;
}

.footer a {
    color: #F4D3DC;
    padding: 0px;
    margin: 0px;
    text-decoration:none;
}

.footer a:hover{
    text-decoration:underline;
}

.mapouter {
    position: static;
    text-align: center;
    width: 100%;
    height: auto;
    display: inline-block;
    border: 0;
}

.mapouter iframe {
    width: 100% !important;
    height: auto !important;
    min-height: 500px;
}

.copyright {
    display: inline-block;
    text-align: center;
}

@media (max-width: 1200px) {

    .footer h4 {
        text-align: left;
        font-size: 1.1rem;
    }

    .footer td {
        font-size: 1.0rem;
        text-align: left;
    }

}

@media (max-width: 1200px) {

    .left_tulip img{
        width: 100%;
    }

    .right_tulip img{
        width:100%;
    }

    .footer h4 {
        text-align: left;
        font-size: 1.2rem;
    }

    .footer td {
        font-size: 1.0rem;
        text-align: left;
    }

}

@media (max-width: 990px) {
    .footer_logo{
        text-align: center;
    }
    .footer_logo img {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer h4 {
        text-align: center;
        font-size: 1.4rem;
    }

    .footer td {
        font-size: 1.4rem;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .footer_logo img {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer h4 {
        text-align: center;
        font-size: 1.2rem;
    }

    .footer td {
        font-size: 1.2rem;
        
    }
}

@media (max-width: 500px) {
    .footer_logo img {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .footer h4 {
        font-size: 1.2rem;
        /* text-align: left; */
    }

    .footer td {
        font-size: 0.9rem;
    }
    .footer ul{
        padding: 0;
    }
    .copyright p {
        font-size: 0.7rem;
    }
}